import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "ad-cost-affiliate",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostAffiliate",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-affiliate",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostAffiliate",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "ad-cost-affiliate",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostAffiliate",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "ad-cost-affiliate",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "language",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostAffiliate",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-affiliate",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostAffiliate",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-affiliate",
        "key": "targetSource",
        "type": "string",
        "format": "",
        "title": "targetSource",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostAffiliate",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-affiliate",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostAffiliate",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "ad-cost-facebook",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-facebook",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebook",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "ad-cost-facebook",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebook",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "ad-cost-facebook",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "language",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-facebook",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-facebook",
        "key": "targetSource",
        "type": "string",
        "format": "",
        "title": "targetSource",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-facebook",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebook",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "ad-cost-facebook-custom",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookCustom",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-facebook-custom",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookCustom",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "ad-cost-facebook-custom",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookCustom",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "ad-cost-facebook-custom",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "language",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookCustom",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-facebook-custom",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookCustom",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-facebook-custom",
        "key": "targetSource",
        "type": "string",
        "format": "",
        "title": "targetSource",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookCustom",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-facebook-custom",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookCustom",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "ad-cost-facebook-daily",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookDaily",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "ad-cost-facebook-daily",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookDaily",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "ad-cost-facebook-daily",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookDaily",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-facebook-daily",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostFacebookDaily",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "ad-cost-google",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostGoogle",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-google",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostGoogle",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "ad-cost-google",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostGoogle",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "ad-cost-google",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "language",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostGoogle",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-google",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostGoogle",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-google",
        "key": "targetSource",
        "type": "string",
        "format": "",
        "title": "targetSource",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostGoogle",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-google",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostGoogle",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "ad-cost-tik-tok",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTikTok",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-tik-tok",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTikTok",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "ad-cost-tik-tok",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTikTok",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "ad-cost-tik-tok",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "language",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTikTok",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-tik-tok",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTikTok",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-tik-tok",
        "key": "targetSource",
        "type": "string",
        "format": "",
        "title": "targetSource",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTikTok",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-tik-tok",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTikTok",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "ad-cost-tiktok-custom",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTiktokCustom",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-tiktok-custom",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTiktokCustom",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "ad-cost-tiktok-custom",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTiktokCustom",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "ad-cost-tiktok-custom",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "language",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTiktokCustom",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-tiktok-custom",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTiktokCustom",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-tiktok-custom",
        "key": "targetSource",
        "type": "string",
        "format": "",
        "title": "targetSource",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTiktokCustom",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost-tiktok-custom",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "AdCostTiktokCustom",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "facebook-account",
        "key": "accessToken",
        "type": "string",
        "format": "",
        "title": "accessToken",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-account",
        "key": "accountId",
        "type": "string",
        "format": "",
        "title": "accountId",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-account",
        "key": "adsFilter",
        "type": "string",
        "format": "text",
        "title": "adsFilter",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "facebook-account",
        "key": "appId",
        "type": "string",
        "format": "",
        "title": "appId",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-account",
        "key": "appSecret",
        "type": "string",
        "format": "",
        "title": "appSecret",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-account",
        "key": "campaignName",
        "type": "string",
        "format": "",
        "title": "campaignName",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-account",
        "key": "campaignNameNot",
        "type": "string",
        "format": "",
        "title": "campaignNameNot",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-account",
        "key": "customOutput",
        "type": "boolean",
        "format": "",
        "title": "customOutput",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "facebook-account",
        "key": "dateFrom",
        "type": "string",
        "format": "date",
        "title": "dateFrom",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "facebook-account",
        "key": "dateTo",
        "type": "string",
        "format": "date",
        "title": "dateTo",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "facebook-account",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "facebook-account",
        "key": "rate",
        "type": "number",
        "format": "float",
        "title": "rate",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "facebook-account",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-account",
        "key": "targetSource",
        "type": "string",
        "format": "",
        "title": "targetSource",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-s2-s",
        "key": "clientData",
        "type": "array",
        "format": "string",
        "title": "clientData",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2S",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "facebook-s2-s",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2S",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "facebook-s2-s",
        "key": "eventData",
        "type": "array",
        "format": "string",
        "title": "eventData",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2S",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "facebook-s2-s",
        "key": "eventId",
        "type": "string",
        "format": "",
        "title": "eventId",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2S",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-s2-s",
        "key": "eventName",
        "type": "string",
        "format": "",
        "title": "eventName",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2S",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "facebook-s2-s",
        "key": "eventTime",
        "type": "integer",
        "format": "",
        "title": "eventTime",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2S",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "facebook-s2-s",
        "key": "fbResponse",
        "type": "array",
        "format": "string",
        "title": "fbResponse",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2S",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "facebook-s2-s",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2S",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "facebook-s2-s",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2S",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "facebook-s2-s-pixel",
        "key": "accessToken",
        "type": "string",
        "format": "",
        "title": "accessToken",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "facebook-s2-s-pixel",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "facebook-s2-s-pixel",
        "key": "origin",
        "type": "string",
        "format": "",
        "title": "origin",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "facebook-s2-s-pixel",
        "key": "pixelId",
        "type": "string",
        "format": "",
        "title": "pixelId",
        "additionalProperties": [],
        "description": "",
        "className": "FacebookS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "google-account",
        "key": "campaignName",
        "type": "string",
        "format": "",
        "title": "campaignName",
        "additionalProperties": [],
        "description": "",
        "className": "GoogleAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "google-account",
        "key": "dateFrom",
        "type": "string",
        "format": "date",
        "title": "dateFrom",
        "additionalProperties": [],
        "description": "",
        "className": "GoogleAccount",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "google-account",
        "key": "dateTo",
        "type": "string",
        "format": "date",
        "title": "dateTo",
        "additionalProperties": [],
        "description": "",
        "className": "GoogleAccount",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "google-account",
        "key": "downloadUrl",
        "type": "string",
        "format": "",
        "title": "DownloadUrl",
        "additionalProperties": [],
        "description": "",
        "className": "GoogleAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "google-account",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "GoogleAccount",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "google-account",
        "key": "rate",
        "type": "number",
        "format": "float",
        "title": "rate",
        "additionalProperties": [],
        "description": "",
        "className": "GoogleAccount",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "google-account",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "Source",
        "additionalProperties": [],
        "description": "",
        "className": "GoogleAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "google-account",
        "key": "targetSource",
        "type": "string",
        "format": "",
        "title": "targetSource",
        "additionalProperties": [],
        "description": "",
        "className": "GoogleAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "7Day",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "advertorial",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "affiliate",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "facebook",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "firstMedium",
        "type": "string",
        "format": "",
        "title": "firstMedium",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "firstMediumLower",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "firstSource",
        "type": "string",
        "format": "",
        "title": "firstSource",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "firstSourceLower",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "google",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "googleOrganic",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "googleYoutube",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "hb",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "order-ad-source",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "newsletter",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "orderId",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "sessionMedium",
        "type": "string",
        "format": "",
        "title": "sessionMedium",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "sessionMediumLower",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "sessionSource",
        "type": "string",
        "format": "",
        "title": "sessionSource",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "sessionSourceLower",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-ad-source",
        "key": "tiktok",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "twitter",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "order-ad-source",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "OrderAdSource",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "columns",
        "type": "string",
        "format": "text",
        "title": "columns",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "exploreId",
        "type": "string",
        "format": "",
        "title": "exploreId",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "folder",
        "type": "rel",
        "format": "sf-explore-data-folder",
        "title": "Folder",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlCount",
        "type": "string",
        "format": "text",
        "title": "sqlCount",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlData",
        "type": "string",
        "format": "text",
        "title": "sqlData",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "snap-chat-s2-s",
        "key": "clientData",
        "type": "array",
        "format": "string",
        "title": "clientData",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2S",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "snap-chat-s2-s",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2S",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "snap-chat-s2-s",
        "key": "eventData",
        "type": "array",
        "format": "string",
        "title": "eventData",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2S",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "snap-chat-s2-s",
        "key": "eventId",
        "type": "string",
        "format": "",
        "title": "eventId",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2S",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "snap-chat-s2-s",
        "key": "eventName",
        "type": "string",
        "format": "",
        "title": "eventName",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2S",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "snap-chat-s2-s",
        "key": "eventTime",
        "type": "integer",
        "format": "",
        "title": "eventTime",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2S",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "snap-chat-s2-s",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2S",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "snap-chat-s2-s",
        "key": "snapChatResponse",
        "type": "array",
        "format": "string",
        "title": "snapChatResponse",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2S",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "snap-chat-s2-s",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2S",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "snap-chat-s2-s-pixel",
        "key": "accessToken",
        "type": "string",
        "format": "",
        "title": "accessToken",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "snap-chat-s2-s-pixel",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "snap-chat-s2-s-pixel",
        "key": "origin",
        "type": "string",
        "format": "",
        "title": "origin",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "snap-chat-s2-s-pixel",
        "key": "pixelId",
        "type": "string",
        "format": "",
        "title": "pixelId",
        "additionalProperties": [],
        "description": "",
        "className": "SnapChatS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "tik-tok-account",
        "key": "accessToken",
        "type": "string",
        "format": "",
        "title": "accessToken",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "tik-tok-account",
        "key": "accountId",
        "type": "string",
        "format": "",
        "title": "accountId",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "tik-tok-account",
        "key": "adsFilter",
        "type": "string",
        "format": "text",
        "title": "adsFilter",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "tik-tok-account",
        "key": "customOutput",
        "type": "boolean",
        "format": "",
        "title": "customOutput",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "tik-tok-account",
        "key": "dateFrom",
        "type": "string",
        "format": "date",
        "title": "dateFrom",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "tik-tok-account",
        "key": "dateTo",
        "type": "string",
        "format": "date",
        "title": "dateTo",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "tik-tok-account",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "tik-tok-account",
        "key": "rate",
        "type": "number",
        "format": "float",
        "title": "rate",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "tik-tok-account",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "tik-tok-account",
        "key": "targetSource",
        "type": "string",
        "format": "",
        "title": "targetSource",
        "additionalProperties": [],
        "description": "",
        "className": "TikTokAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "tiktok-s2-s",
        "key": "clientData",
        "type": "array",
        "format": "string",
        "title": "clientData",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2S",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "tiktok-s2-s",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2S",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "tiktok-s2-s",
        "key": "eventData",
        "type": "array",
        "format": "string",
        "title": "eventData",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2S",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "tiktok-s2-s",
        "key": "eventId",
        "type": "string",
        "format": "",
        "title": "eventId",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2S",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "tiktok-s2-s",
        "key": "eventName",
        "type": "string",
        "format": "",
        "title": "eventName",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2S",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "tiktok-s2-s",
        "key": "eventTime",
        "type": "integer",
        "format": "",
        "title": "eventTime",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2S",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "tiktok-s2-s",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2S",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "tiktok-s2-s",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2S",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "tiktok-s2-s",
        "key": "tiktokResponse",
        "type": "array",
        "format": "string",
        "title": "tiktokResponse",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2S",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "tiktok-s2-s-pixel",
        "key": "accessToken",
        "type": "string",
        "format": "",
        "title": "accessToken",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "tiktok-s2-s-pixel",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "tiktok-s2-s-pixel",
        "key": "origin",
        "type": "string",
        "format": "",
        "title": "origin",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "tiktok-s2-s-pixel",
        "key": "pixelId",
        "type": "string",
        "format": "",
        "title": "pixelId",
        "additionalProperties": [],
        "description": "",
        "className": "TiktokS2SPixel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "authSourceId",
        "type": "integer",
        "format": "",
        "title": "authSourceId",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "utm-storage",
        "key": "analyticsId",
        "type": "string",
        "format": "",
        "title": "analyticsId",
        "additionalProperties": [],
        "description": "",
        "className": "UtmStorage",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "utm-storage",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "UtmStorage",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "utm-storage",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "email",
        "additionalProperties": [],
        "description": "",
        "className": "UtmStorage",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "utm-storage",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "UtmStorage",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "utm-storage",
        "key": "utmCampaign",
        "type": "string",
        "format": "",
        "title": "utmCampaign",
        "additionalProperties": [],
        "description": "",
        "className": "UtmStorage",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "utm-storage",
        "key": "utmMedium",
        "type": "string",
        "format": "",
        "title": "utmMedium",
        "additionalProperties": [],
        "description": "",
        "className": "UtmStorage",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "utm-storage",
        "key": "utmSource",
        "type": "string",
        "format": "",
        "title": "utmSource",
        "additionalProperties": [],
        "description": "",
        "className": "UtmStorage",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "utm-storage",
        "key": "uuid",
        "type": "string",
        "format": "",
        "title": "uuid",
        "additionalProperties": [],
        "description": "",
        "className": "UtmStorage",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    }
]