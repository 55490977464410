import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "AdCostAffiliate",
        "schema": "ad-cost-affiliate",
        "title": "Ads cost",
        "titlePlural": "Ads cost",
        "required": [],
        "scopes": []
    },
    {
        "className": "AdCostFacebook",
        "schema": "ad-cost-facebook",
        "title": "Ads cost",
        "titlePlural": "Ads costs",
        "required": [],
        "scopes": []
    },
    {
        "className": "AdCostFacebookCustom",
        "schema": "ad-cost-facebook-custom",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "AdCostFacebookDaily",
        "schema": "ad-cost-facebook-daily",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "AdCostGoogle",
        "schema": "ad-cost-google",
        "title": "Ads cost",
        "titlePlural": "Ads costs",
        "required": [],
        "scopes": []
    },
    {
        "className": "AdCostTikTok",
        "schema": "ad-cost-tik-tok",
        "title": "Ads cost",
        "titlePlural": "Ads costs",
        "required": [],
        "scopes": []
    },
    {
        "className": "AdCostTiktokCustom",
        "schema": "ad-cost-tiktok-custom",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "FacebookAccount",
        "schema": "facebook-account",
        "title": "Facebook account",
        "titlePlural": "Facebook accounts",
        "required": [],
        "scopes": []
    },
    {
        "className": "FacebookS2S",
        "schema": "facebook-s2-s",
        "title": "Facebook S2S",
        "titlePlural": "Facebook S2S",
        "required": [],
        "scopes": []
    },
    {
        "className": "FacebookS2SPixel",
        "schema": "facebook-s2-s-pixel",
        "title": "Facebook S2S pixel",
        "titlePlural": "Facebook S2S pixel",
        "required": [],
        "scopes": []
    },
    {
        "className": "GoogleAccount",
        "schema": "google-account",
        "title": "Google Account",
        "titlePlural": "Google Accounts",
        "required": [],
        "scopes": []
    },
    {
        "className": "OrderAdSource",
        "schema": "order-ad-source",
        "title": "Orders ad source",
        "titlePlural": "Orders ad source",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder",
        "title": "Explore data folder",
        "titlePlural": "Explore data folder",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item",
        "title": "Explore data",
        "titlePlural": "Explore data",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SnapChatS2S",
        "schema": "snap-chat-s2-s",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "SnapChatS2SPixel",
        "schema": "snap-chat-s2-s-pixel",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "TikTokAccount",
        "schema": "tik-tok-account",
        "title": "TikTok account",
        "titlePlural": "TikTok accounts",
        "required": [],
        "scopes": []
    },
    {
        "className": "TiktokS2S",
        "schema": "tiktok-s2-s",
        "title": "Tiktok S2S",
        "titlePlural": "Tiktok S2S",
        "required": [],
        "scopes": []
    },
    {
        "className": "TiktokS2SPixel",
        "schema": "tiktok-s2-s-pixel",
        "title": "Tiktok S2S pixel",
        "titlePlural": "Tiktok S2S pixel",
        "required": [],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "UtmStorage",
        "schema": "utm-storage",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "AdCostFacebook": {
        "className": "AdCostFacebook",
        "schema": "ad-cost-facebook"
    },
    "AdCostGoogle": {
        "className": "AdCostGoogle",
        "schema": "ad-cost-google"
    },
    "FacebookAccount": {
        "className": "FacebookAccount",
        "schema": "facebook-account"
    },
    "GoogleAccount": {
        "className": "GoogleAccount",
        "schema": "google-account"
    },
    "OrderAdSource": {
        "className": "OrderAdSource",
        "schema": "order-ad-source"
    },
    "FacebookS2S": {
        "className": "FacebookS2S",
        "schema": "facebook-s2-s"
    },
    "FacebookS2SPixel": {
        "className": "FacebookS2SPixel",
        "schema": "facebook-s2-s-pixel"
    },
    "TikTokAccount": {
        "className": "TikTokAccount",
        "schema": "tik-tok-account"
    },
    "AdCostTikTok": {
        "className": "AdCostTikTok",
        "schema": "ad-cost-tik-tok"
    },
    "TiktokS2S": {
        "className": "TiktokS2S",
        "schema": "tiktok-s2-s"
    },
    "TiktokS2SPixel": {
        "className": "TiktokS2SPixel",
        "schema": "tiktok-s2-s-pixel"
    },
    "SfKeyValueOrm": {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm"
    },
    "User": {
        "className": "User",
        "schema": "user"
    },
    "AdCostAffiliate": {
        "className": "AdCostAffiliate",
        "schema": "ad-cost-affiliate"
    },
    "SfExploreDataItem": {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item"
    },
    "SfExploreDataFolder": {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder"
    },
    "AdCostFacebookCustom": {
        "className": "AdCostFacebookCustom",
        "schema": "ad-cost-facebook-custom"
    },
    "UtmStorage": {
        "className": "UtmStorage",
        "schema": "utm-storage"
    },
    "AdCostFacebookDaily": {
        "className": "AdCostFacebookDaily",
        "schema": "ad-cost-facebook-daily"
    },
    "AdCostTiktokCustom": {
        "className": "AdCostTiktokCustom",
        "schema": "ad-cost-tiktok-custom"
    },
    "SnapChatS2S": {
        "className": "SnapChatS2S",
        "schema": "snap-chat-s2-s"
    },
    "SnapChatS2SPixel": {
        "className": "SnapChatS2SPixel",
        "schema": "snap-chat-s2-s-pixel"
    }
}