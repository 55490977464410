import React from 'react'

interface Props {
    
}

export default function Columns(props: Props) {
    return (
        <div>Columns</div>
    )
}
